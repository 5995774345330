<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<div class="whatsapp">
    <a href="https://wa.me/{{settings.whatsapp}}">
    <img src="assets/whatsapp.png" alt="" srcset="">
    </a>
</div>

<div class="phone">
    <a href="tel:{{settings.phoneNo}}">
    <img src="assets/callicon.png" alt="" srcset="">
    </a>
</div>